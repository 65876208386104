import React, { useState, useEffect } from "react";
import GhostContentAPI from "@tryghost/content-api";
import { Fade } from "react-reveal";
import TeaserCard from "../molecules/TeaserCard";
import GrayBgArtwork from "../atoms/GrayBgArtwork";
import styled from "styled-components";
import moment from "moment";

const StyledGrayBgArtwork = styled(GrayBgArtwork)`
  z-index: -1;
`;

const BlogPosts = ({ limit, hasArtwork, hasDelay }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    new GhostContentAPI({
      url: "https://dij-blog.ghost.io",
      key: "774da3a452fb384473889f48bf",
      version: "v2",
    }).posts
      .browse({ limit: limit, include: "tags,authors" })
      .then((posts) => {
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [limit]);

  const renderPosts = (posts) =>
    posts.map((post, index) => (
      <Fade bottom distance="50px" key={post.id} delay={hasDelay && index * 50}>
        <div className="col-sm-6 col-md-4">
          <TeaserCard
            image={post.feature_image}
            title={post.title}
            href={post.url}
            date={moment(post.published_at).format("DD-MM-YYYY")}
            author={post.meta_description}
            isBlogPost={true}
          >
            {post.excerpt}
          </TeaserCard>
        </div>
      </Fade>
    ));

  return (
    <div className="row">
      {renderPosts(posts)}
      {hasArtwork && (
        <StyledGrayBgArtwork locationX="bottom" locationY="left" size="large" />
      )}
    </div>
  );
};

export default BlogPosts;
