import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledGrayBgArtwork = styled.div`
  position: absolute;
  background-color: ${color.gray.light};
  ${(props) => {
    if (props.locationX === "left") {
      return "left: -60px";
    }
    if (props.locationX === "right") {
      return "right: -60px";
    }
    return "left: -60px";
  }};
  ${(props) => {
    if (props.locationY === "top") {
      return "top: -60px";
    }
    if (props.locationX === "bottom") {
      return "bottom: -60px";
    }
    return "bottom: -60px";
  }};
  width: ${(props) => {
    if (props.size === "large") {
      return `625px`;
    }
    return "300px";
  }};
  height: 100%;
  z-index: 0;
`;

const GrayBgArtwork = ({ className, locationY, locationX, size }) => (
  <StyledGrayBgArtwork
    className={className}
    locationY={locationY}
    locationX={locationX}
    size={size}
  />
);

export default GrayBgArtwork;
