import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import ReadMoreButton from "../atoms/ReadMoreButton";

const StyledTeaserCard = styled.article`
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
`;

const StyledLink = styled.a`
  transition: all 0.15s ease-in-out;
  position: relative;
  display: block;
  text-decoration: none;
  color: white;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0);

  &:hover,
  &:focus {
    transform: scale(1.025);
    box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.25);

    h1 {
      color: ${color.primary.base};
    }
  }
`;

const StyledCardTopSection = styled.div`
  transition: transform 0.2s ease-in-out;
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 240px;
  transform: scale(1);
  z-index: 0;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(10, 11, 9, 0) 0%,
      rgba(10, 11, 9, 1) 100%
    );
    z-index: 1;
  }
`;

const StyledBgImage = styled.img`
  transition: all 0.2s ease-in-out;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1);
`;

const StyledBodyWrapper = styled.div`
  background-color: ${color.base.base};
  padding: 0 20px 20px 20px;
  margin-top: -1px;

  @media only screen and (min-width: 768px) {
    min-height: 355px;
  }

  @media only screen and (min-width: 992px) {
    min-height: 305px;
  }
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitleWrapper = styled.div`
  align-self: flex-end;
  padding: 10px 20px;
  width: 100%;
`;

const StyledTitle = styled.h1`
  transition: color 0.15s ease-in-out;
  position: relative;
  align-self: flex-end;
  font-size: 20px;
  line-height: 1.5rem;
  z-index: 2;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 15px;
  color: ${color.gray.base};
  max-height: ${(props) => (props.isBlogPost ? "125px" : "none")};
  overflow: hidden;
`;

const StyledDate = styled.span`
  font-size: 14px;
  color: ${color.gray.dark};
`;

const Author = styled.span`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray.dark};
`;

const TeaserCard = ({
  className,
  image,
  title,
  children,
  href,
  date,
  author,
  isBlogPost,
}) => (
  <StyledTeaserCard className={className}>
    <StyledLink href={href} target="_blank">
      <StyledCardTopSection className="blog__top-section">
        <StyledBgImage src={image} />
        <StyledTitleWrapper>
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleWrapper>
      </StyledCardTopSection>
      <StyledBodyWrapper>
        <StyledText isBlogPost={isBlogPost}>{children}</StyledText>
        <Author>{author}</Author>
        <StyledBottomWrapper>
          {href ? <ReadMoreButton>Lees meer</ReadMoreButton> : <div />}
          <StyledDate>{date}</StyledDate>
        </StyledBottomWrapper>
      </StyledBodyWrapper>
    </StyledLink>
  </StyledTeaserCard>
);

export default TeaserCard;
